import React from "react";
import { useState, useEffect, useRef } from 'react'

const ChatMessage = ({ message, interviewerName, customerName }) => (
    <div className={`flex text-white ${message.role === "assistant" ? "justify-start" : "justify-end"} gap-x-5 p-4 m-4 items-center`}>
        <div className="grid grid-cols-1">
            <div className={`text-xs font-medium m-1 ${message.role === "assistant" ? "text-left" : "text-right"}`}>
                {message.role === "assistant" ? interviewerName : customerName}
            </div>
            <div
                className={"text-left bg-neutral-600 backdrop-blur-xl shadow-sm rounded-lg p-4 max-w-lg"}
            >
                <p className='text-sm text-gray-200 font-medium'>{message.content}</p>
            </div>
        </div>
    </div>
);


const ChatInput = ({ newMessageText, onChange, onKeyDown, onSubmit, loadingStatus }) => (
    <div className="z-20 w-fit ml-[2rem] sm:w-full sm:ml-0">
        <form className="flex items-end" onSubmit={onSubmit}>
            <textarea
                className="mr-2 text-xs text-gray-200 grow resize-none rounded-md font-medium h-10 p-2 bg-gray-100 bg-opacity-10  shadow-sm focus:border-blue-600 focus:outline-none"
                value={newMessageText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder="Please enter your response"
            />
            <button
                className={`h-10 rounded-md ${loadingStatus
                    ? "bg-slate-900 bg-transparent-90 shadow-sm"
                    : "bg-sky-800 bg-opacity-20 shadow-sm hover:bg-sky-700 hover:bg-opacity-20"
                    } px-1 py-1`}
                type="submit"
                disabled={loadingStatus}
            >
                <p className="font-medium text-white text-xs mx-3">Enter</p>
            </button>
        </form>
    </div>
);

export default function Chat(props) {
    const scrollEndRef = useRef(null)
    const [messages, setMessages] = useState([]);
    const [newMessageText, setNewMessageText] = useState("");
    const [interviewStatus, setInterviewStatus] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const socket = props.socket

    useEffect(() => {

        scrollEndRef.current?.scrollIntoView({ behavior: "smooth" });

        const handleAssistantReply = (question) => {
            setMessages((prevMessages) => { return [...prevMessages, { 'role': 'assistant', 'content': question }] });
            console.log(messages)
        }

        const handleInterviewEnd = (endMessage) => {
            console.log("INTERVIEW ENDED")
            let data = {
                'linkID': props.linkID,
                'messages': messages
            }
            setInterviewStatus(false)
            socket.emit('interview-ended', data)
            setMessages((prevMessages) => { return [...prevMessages, { 'role': 'assistant', 'content': endMessage }] });
        }

    socket.on('interview-agent-question', handleAssistantReply)
    socket.on('interview-end', handleInterviewEnd)

    return () => {
        socket.off('interview-agent-question', handleAssistantReply);
        socket.off('interview-end', handleInterviewEnd);
        setLoadingStatus(false);
    }

    }, [messages])

const onChange = (event) => setNewMessageText(event.target.value);
const onKeyDown = (event) => {

    if (event.keyCode === 13 && event.shiftKey === false) {
        onSubmit(event);
        setNewMessageText("");
    }
};

const onSubmit = (event) => {
    event.preventDefault();
    socket.emit('customer-interview-response', { "message": newMessageText })
    setMessages((prevMessages) => { return [...prevMessages, { 'role': 'user', 'content': newMessageText }] });
    setLoadingStatus(true);
    setNewMessageText("");
};


return (
    <>
        <section className="mx-auto h-screen w-screen flex justify-center items-center pt-8 overflow-none sm:overflow-auto">
            <div className="h-[90%] w-[70%] flex flex-col bg-white bg-opacity-20 rounded-xl">
                <div className="flex-grow overflow-y-auto text-xs font-medium">
                    {messages.map((message, index) => (
                        <ChatMessage key={index.toString()} message={message} interviewerName={props.interviewerName} customerName={props.customerName}/>
                    ))}
                    {loadingStatus && (
                            <div className="m-5 p-5">
                                <p className="font-bold"> Interviewer is replying...</p>
                            </div>
                    )}
                        <div ref={scrollEndRef}/>
                </div>
                <div className="m-3 grid items-center">
                    <div className="mx-auto w-[750px]">
                        {interviewStatus ?
                            <ChatInput
                                newMessageText={newMessageText}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onSubmit={onSubmit}
                                loadingStatus={loadingStatus}
                            />
                            :
                            <ChatInput
                                newMessageText={""}
                                loadingStatus={loadingStatus}
                            />
                        }
                    </div>
                </div>
            </div>
        </section>

    </>
)

}