export default function Footer(props) {
    return (
        <footer className="absolute right-0 bottom-0 w-full animate-fadeIn flex justify-between items-center h-14 px-4 bg-transparent overflow-none">
            <div className="flex flex-row">
            <svg className="w-7 h-7" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
                  <stop stopColor="#8914DE" offset="0%" />
                  <stop stopColor="#8914DE" offset="25.871%" />
                  <stop stopColor="#338CF5" offset="100%" />
                </radialGradient>
              </defs>
              <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" />
            </svg>
            <h1 className='ml-2 mb-1 text-lg font-bold text-white' onClick={() => window.location="https://entropi.app"}>Entropi</h1>
          </div>
            <div className="flex gap-4">
            <a href="https://entropi.app/terms" className="text-sm text-gray-300 hover:underline">Terms</a>
            <a href="https://entropi.app/privacy" className="text-sm text-gray-300 hover:underline">Privacy</a>
            </div>
        </footer>
    );
}