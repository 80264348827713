import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Onboarding from '../components/Onboarding';
import Chat from "../components/Chat";
import InvalidID from './InvalidID';


export default function Interview(props) {
    // stage 1 
    // stage
    const socket = props.socket
    const chatID = useParams()["chatID"];
    const [stage, setStage] = useState(0);
    const [linkValid, setLinkValid] = useState(false)
    const [loading, setLoading] = useState(true)
    const [company, setCompany] = useState({})
    const [name, setName] = useState("")
    const [bgImage, setBgImage] = useState("")

    useEffect(() => {
        validateId()
    }, [chatID])

    function pickBG() {
        let options = {
            0: "https://mypeerai.s3.amazonaws.com/christ_the_redeemer.png",
            1: "https://mypeerai.s3.amazonaws.com/barcelona.png",
            2: "https://mypeerai.s3.amazonaws.com/taj_mahal.png",
            3: "https://mypeerai.s3.amazonaws.com/macchu_picchu.png",
            4: "https://mypeerai.s3.amazonaws.com/mount_fuji.png",
            5: "https://mypeerai.s3.amazonaws.com/great_wall.png",
            6: "https://mypeerai.s3.amazonaws.com/greece.png",
        }

        let selected = Math.floor(Math.random() * 7)
        console.log(selected)
        if (bgImage == "")
            setBgImage(options[selected])
    }


    function validateId() {
        let validate_uuid = isUUID(chatID)
        if (validate_uuid) {
            pickBG()
            var query = {
                'collection': 'links',
                'linkID': chatID
            }
            socket.emit('get-mongo', query)
            socket.on('response', (data) => {
                data = JSON.stringify(data)
                var parsed = JSON.parse(data)
                if (parsed['type'] === 'get-links') {
                    let linkData = parsed['data']
                    if (linkData[0]['orgID']) {
                        let orgQuery = {
                            'collection': 'organizations',
                            'orgID': linkData[0]['orgID']
                        }
                        socket.emit('get-mongo', orgQuery)
                        if (linkData.length === 0) {
                            window.location = '/error'
                        }
                        if (linkData[0].status === "complete") {
                            window.location = '/error'
                        }
                        setLinkValid(true)
                    }
                }
                if (parsed['type'] === 'get-org') {
                    setCompany(parsed['data'][0])
                }
            })

            let updateLinkStatusQuery = {
                "collection": "links",
                "query": { "linkID": chatID },
                "update": { "status": "opened" }
            }
            socket.emit('update-mongo', updateLinkStatusQuery)

        }
        else
            window.location = '/error'
    }

    function isUUID(str) {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(str);
    }


    return (
        <>
            {bgImage != "" && 
            <div className="relative h-screen overflow-y-auto">
                <div className="absolute inset-0 bg-cover overflow-auto blur-md brightness-50" style={{ backgroundImage: `url(${bgImage})` }}></div>
                <div className={`absolute inset-0 flex h-full w-full ${stage != 2 ? "" : "items-center"}justify-center`}>
                    {
                        stage != 2 ?
                            <Onboarding stage={stage} setStage={setStage} company={company} socket={socket} linkID={chatID} name={name} setName={setName} />
                            :
                            <div className="h-screen w-screen">
                                <Chat socket={socket} linkID={chatID} interviewerName={"Interviewer"} customerName={name} />
                            </div>
                    }
                </div>
                <Footer />
                
            </div>}
        </>
    )
}