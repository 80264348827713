import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { io } from "socket.io-client";
import Chat from './components/Chat'
import InvalidID from './pages/InvalidID';
import Interview from './pages/Interview';

function App() {
  const socket = io('https://api.entropi.app', {
    rejectUnauthorized: false
  })

  return (
      <Router>
        <Routes>
          <Route exact path="/:chatID" element={<Interview socket={socket}/>} />
          <Route exact path="/error" element={<InvalidID />} />
          <Route exact path="/chattest" element={<Chat />} />
        </Routes>
      </Router>
  );
}

export default App;