import { Transition } from 'react-transition-group';
import { useState, useEffect } from 'react'
import Avvvatars from 'avvvatars-react'

export default function Onboarding(props) {
    const stage = props.stage
    const setStage = props.setStage
    const company = props.company
    const [showSubheading, setShowSubheading] = useState(false);
    const [showInputs, setShowInputs] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [occupation, setOccupation] = useState("")
    const setName = props.setName


    useEffect(() => {
        setTimeout(() => {
            setShowSubheading(true);
        }, 1750);


        setTimeout(() => {
            setShowInputs(true);
        }, 2000);

    })

    const duration = 300;
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };

    function startInterview() {
        setName(firstName+" "+lastName)

        var profileUpdate = {
            "userInfo.name": firstName+" "+lastName,
            "userInfo.email": email,
            "userInfo.occupation": occupation,
            "status": "started"
        }
        var chatData = {
            'linkID': props.linkID,
        }

        var updateQuery = {
            'collection': 'links',
            'query': chatData,
            'update': profileUpdate
        }
        props.socket.emit('update-mongo', updateQuery)
        props.socket.emit('start-interview', chatData)
        setStage(2)
    }

    function checkInput() {
        return email.length > 0 && firstName.length > 0 && lastName.length > 0 && occupation.length > 0
    }

    function handleCheck() {
        setTermsAgreed(!termsAgreed)
    }


    return (<>
        <div className="grid grid-cols-1 w-full m-14">
        <div>
        <div className="animate-fadeIn flex flex-row gap-4 items-center justify-start">
                {company?.orgImage ? <img className="h-16 w-16 rounded-full object-cover" src={company.orgImage}/> : <Avvvatars size={60} value={company?.orgName}/>}
                <p className="text-4xl text-white font-bold">{company?.orgName}</p>
            </div>
        </div>
            
            <div className="flex flex-col items-center h-full">
            
            <Transition in={showInputs}>
                {state => (
                    <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center w-full">
                        <div style={{ ...defaultStyle, ...transitionStyles[state] }} className="flex flex-col items-center gap-10">
                            <input id="firstName" className="text-2xl mt-1 bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60 p-2 text-white font-medium w-[70%] focus:outline-none" type="text" placeholder={"First Name"} onChange={(e) => setFirstName(e.target.value)} />
                            <input id="lastName" className="text-2xl mt-1 bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60 p-2 text-white font-medium w-[70%] focus:outline-none" type="text" placeholder={"Last Name"} onChange={(e) => setLastName(e.target.value)} />
                            <input id="email" className="text-2xl mt-1 bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60 p-2 text-white font-medium w-[70%] focus:outline-none" type="email" placeholder={"Email"} onChange={(e) => setEmail(e.target.value)} />
                            <input id="occupation" className="text-2xl mt-1 bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60 p-2 text-white font-medium w-[70%] focus:outline-none" type="text" placeholder={"Occupation"} onChange={(e) => setOccupation(e.target.value)} />
                        </div>
                        <div>
                            {
                                checkInput() ?
                                    <div className="mx-auto flex flex-col items-center gap-10 mt-8 animate-fadeIn">
                                        <p className="text-white text-5xl font-semibold text-center">Ready to jump in?</p>
                                        <div className="flex flex-row gap-3">
                                            <input className="bg-red-400 accent-blue-700 bg-opacity-10 " type="checkbox" onChange={handleCheck} />
                                            <p className="text-sky-100 text-sm font-semibold text-left">I agree to the <span className="underline" onClick={() => window.location = 'https://entropi.app/terms'}>terms</span></p>
                                        </div>

                                        <div className={`${termsAgreed ? '' : 'hidden'} w-full text-center`}>
                                            <button className="text-gray-700 p-2 px-4 bg-green-400  rounded-md font-bold uppercase" onClick={startInterview}>Go</button>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                )}
            </Transition>
            
            </div>
            


        </div>
    </>)
}